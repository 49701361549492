import Vue from "vue";
import VueRouter from "vue-router";
import Router from 'vue-router';
import { Campaign } from '@/common/campaign';
import { Cookie } from '@/common/cookie';
import { accountRoutes } from './account.routes';
import { campaignRoutes } from './campaign.routes';
import { errorRoutes } from './error.routes';
import { voiceRoutes } from './voice.routes';
import { billingRoutes } from './billing.routes';
import { dataRoutes } from './data.routes';
import { productRoutes } from './product.routes';
import { Theme } from '@/common/theme';
const Base = () => import('@/views/base.view.vue');
const Login = () => import("@/views/auth/auth-login.view.vue");
const SignUp = () => import("@/views/auth/auth-sign-up.view.vue");
const EmailVerification = () => import("@/views/auth/auth-email-verification.view.vue");
const PasswordReset = () => import("@/views/auth/auth-password-reset.view.vue");
const ForgotPassword = () => import("@/views/auth/auth-forgot-password.view.vue");
const LegacyUserForgotPassword = () => import("@/views/auth/auth-legacy-user-forgot-password.view.vue");
const VerifyAccount = () => import("@/views/auth/auth-verify-account.view.vue");
const ReVerifyAccount = () => import("@/views/auth/auth-re-verify-account.view.vue");
const AuthGoogleAccount = () => import("@/views/auth/auth-google.view.vue");
const AuthFacebookAccount = () => import("@/views/auth/auth-facebook.view.vue");
const AuthMicrosoftAccount = () => import("@/views/auth/auth-mircosoft.view.vue");
const Dashboard = () => import("@/views/dashboard/dashboard.view.vue");
const DeviceConfigure = () => import("@/views/voice/fusion/device/device-configure.view.vue");
const GeneralMaintainence = () => import("@/views/general/general-maintenance.view.vue");
const CoverageChecker = () => import("@/views/public/coverage-checker/coverage.checker.view.vue");

Vue.use(VueRouter);
const routes = [
    // [/]
    {
        path: "/",
        name: "main",
        redirect: "/login",
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: false,
            icon: null,
        }
    },
    // [auth]
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null,
        }
    },
    {
        path: "/sign-up",
        name: "sign-up",
        component: SignUp,
        meta: {
            navigation: false,
            isPublic: true,
            sideNavBar: false,
            icon: null
        }
    },
    {
        path: "/email-verification/:email",
        name: "email-verification",
        component: EmailVerification,
        meta: {
            navigation: false,
            isPublic: true,
            sideNavBar: false,
            icon: null
        }
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null
        }
    },
    {
        path: "/password-reset",
        name: "password-reset",
        component: PasswordReset,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null
        }
    },
    {
        path: "/legacy-user",
        name: "legacy-user",
        component: LegacyUserForgotPassword,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null,
        }
    },
    {
        path: "/verify-account",
        name: "verify-account",
        component: VerifyAccount,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null
        }
    },
    {
        path: "/re-verify-account/:email",
        name: "re-verify-account",
        component: ReVerifyAccount,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null
        }
    },
    {
        path: "/auth-google",
        name: "auth-google",
        component: AuthGoogleAccount,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null
        }
    },
    {
        path: "/auth-facebook",
        name: "auth-facebook",
        component: AuthFacebookAccount,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null
        }
    },
    {
        path: "/auth-microsoft",
        name: "auth-microsoft",
        component: AuthMicrosoftAccount,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null
        }
    },
    // [auth]
    {
        path: "/coverage-checker",
        name: "coverage-checker",
        component: CoverageChecker,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null,
        }
    },
    // [dashboard]
    {
        path: "/dashboard",
        name: "dashboard",
        beforeEnter: (to: any, from: any, next: any) => {
            Campaign.check(to, from, next);
        },
        component: Dashboard,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'tachometer-alt'
        },
    },
    // [account]
    {
        path: '/account',
        name: 'account',
        redirect: '/account/manage',
        component: Base,
        beforeEnter: (to: any, from: any, next: any) => {
            Campaign.check(to, from, next);
        },
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: false,
            icon: 'users',
        },
        children: accountRoutes
    },
    // [campaign]
    {
        path: '/campaign',
        redirect: '/campaign/voice',
        name: 'voice comms campaign',
        component: Base,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: false,
            icon: 'circle'
        },
        children: campaignRoutes
    },
    // [errors]
    {
        path: '*',
        redirect: '/not-found',
        name: 'catch all',
        component: Base,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: false,
            icon: 'circle'
        },
        children: errorRoutes
    },
    // [voice]
    {
        path: "/voice",
        name: "voice services",
        component: Base,
        beforeEnter: (to: any, from: any, next: any) => {
            Campaign.check(to, from, next);
        },
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'phone',
        },
        children: voiceRoutes
    },
    // [data]
    {
        path: "/data",
        name: "Data Services",
        beforeEnter: (to: any, from: any, next: any) => {
            Campaign.check(to, from, next);
        },
        component: Base,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: false,
            icon: 'wifi'
        },
        children: dataRoutes
    },
    // [products]
    {
        path: "/products",
        name: "My Products",
        beforeEnter: (to: any, from: any, next: any) => {
            Campaign.check(to, from, next);
        },
        component: Base,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'box'
        },
        children: productRoutes
    },
    // [billing]
    {
        path: "/bill",
        name: "Billing & Invoices",
        component: Base,
        beforeEnter: (to: any, from: any, next: any) => {
            Campaign.check(to, from, next);
        },
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'file-invoice',
        },
        children: billingRoutes
    },
    {
        path: '/device/configure',
        name: 'device configure',
        component: DeviceConfigure,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: 'exchange-alt',
        }
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: GeneralMaintainence,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: 'exchange-alt',
        }
    },
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return document.querySelector(to.hash)!.scrollIntoView({ behavior: 'smooth' });
        } else {
            return savedPosition || { x: 0, y: 0 }
        }
    }
});
// [route gaurd and persmissions blocker]
router.beforeEach((to, from, next) => {
    // [check for cookie]
    let token = Cookie.get('MTLAUTH');
    let isAuthenticated = false;
    let isAuthorized = false;
    if (token) {
        let validToken = Cookie.parseToken(token);
        if (validToken) {
            if (validToken.userId && validToken.siteKey === 'portal.mytelnet.co.za') {
                isAuthenticated = true;
                isAuthorized = true;
            }
        }
    }
    // [routes]
    if (isAuthenticated) {
        if (isAuthorized) {
            next();
        }
        else {
            // [unauthorized screen]
            if (to.path !== "/unauthorized") {
                next('/unauthorized')
            }
        }
    }
    else {
        // [back to login screen]
        if (!to?.meta?.isPublic) {
            Cookie.remove("MTLAUTH");
            Theme.setTheme("auth");
            next(`/login?ref=${to.path}`)
        }
        next();
    }

});
// [swallow duplicate routing error when a link is clicked]
const originalPush: any = Router.prototype.push;
Router.prototype.push = function push(location: any) {
    return originalPush.call(this, location).catch((err: any) => err)
};
export default router;